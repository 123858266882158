<template>
  <div
    id="news-wrapper"
    :class="news.length === 0 ? 'no-news' : ''"
  >
    <v-container
      id="news-container"
      v-resize="onResize"
      fluid
    >
      <template v-if="newsLoaded">
        <template v-if="news.length">
          <template v-for="(newsArticle, index) in news">
            <news-article-top
              v-if="index === 0"
              :key="newsArticle.id"
              :news-article="newsArticle"
              :drawer-opened="isDrawerOpen"
              class="top"
              @article-opened-closed="overlay => setArticlesStyle(overlay)"
            />
            <news-article
              v-else
              :key="newsArticle.id"
              :news-article="newsArticle"
              :class="['news-articles', 'n-' + (index % 5 || 5)]"
              :background="[blue, red][(index % 5 || 5) % 2]"
              :drawer-opened="isDrawerOpen"
              color="white"
              @article-opened-closed="overlay => setArticlesStyle(overlay)"
            />
            <hr
              v-show="calculateHrElementPosition(index)"
              :key="`hr-${newsArticle.id}`"
              class="posts-separator"
            >
          </template>
        </template>
        <template v-else>
          <v-card class="top">
            <v-card-title>{{ $t('base/news.no_news') }}</v-card-title>
          </v-card>
        </template>
      </template>
    </v-container>
  </div>
</template>

<script>
import { api } from '@/global/services/api'
import NewsArticle from '@/modules/base-module/home/views/NewsArticle'
import NewsArticleTop from './NewsArticleTop'
import { isDrawerOpen } from '../js/homeViewHelpers'

export default {
  name: 'HomeView',
  components: { NewsArticle, NewsArticleTop },
  data () {
    return {
      news: [],
      newsLoaded: false,
      blue: '#1034A6',
      red: '#FA292A',
      isDrawerOpen: null
    }
  },

  async created () {
    const { data } = await api('base').get('news')
    this.news = data
    this.news.forEach(article => {
      if (!article.properties.image) {
        article.properties.image = {
          fit: true,
          url: '/public/base/images/ecoMobile.jpg'
        }
      }
    })
    this.newsLoaded = true
    this.$nextTick(() => {
      if (screen.width > 480) {
        this.setArticlesStyle(false)
      }
    })
  },

  mounted () {
    this.onResize()
  },

  methods: {
    calculateHrElementPosition (index) {
      return screen.width > 767
        ? index % 5 === 0 || index % 5 === 3
        : true
    },

    onResize () {
      this.isDrawerOpen = isDrawerOpen()
    },

    setArticlesStyle (overlay) {
      const articlesAndHrs = document.querySelectorAll('.news-articles, .posts-separator')
      const transform = overlay ? 'unset' : 'translateY(-50px)'
      articlesAndHrs.forEach(articleOrHr => (articleOrHr.style.transform = transform))
    }
  }
}
</script>

<style scoped lang="scss">
#news-wrapper {
  min-height: calc(100vh - 64px); // 64px - height of a navbar
  background-color: #fff;
}

#news-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.5rem;
  padding: 1.5rem;
  * {
    grid-column: span 6;
  }
}

.no-news {
  --news-container-border-height: 0px;
}

@media (max-width: 767px) {
  #news-container{
    padding: 0 0 1.5rem 0;
    .posts-separator {
      margin: 0 1.25rem;
    }
    *:not(:first-child){
      padding: 0 1.25rem;
    }
  }
}

@media (min-width: 768px) and (max-width: 1239px){
  #news-container {
    width: 100%;
    .posts-separator {
      margin: 0.5rem 0;
    }
    .top {
      grid-column: span 6;
    }
    .n-1, .n-2, .n-3 {
      grid-column: span 2;
    }
    .n-4, .n-5 {
      grid-column: span 3;
    }
  }
}

@media screen and (min-width: 1240px) {
  #news-container {
    width: 60%;
    &:before{
      content : "";
      position: absolute;
      left    : 20%;
      top  : 0.5%;
      height  : var(--news-container-border-height,99%);
      width   : 60%;
      border-left: 2px solid #E2E2E2;
      border-right: 2px solid #E2E2E2;
    }
    .posts-separator {
      margin: 0.5rem 0;
      color: #E2E2E2;
      background-color: #E2E2E2;
    }
    .top {
      grid-column: span 6;
    }
    .n-1, .n-2, .n-3 {
      grid-column: span 2;
    }
    .n-4, .n-5 {
      grid-column: span 3;
    }
  }
}
</style>
