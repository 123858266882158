<template>
  <div
    class="news-article"
  >
    <!-- Showcase card / open button -->
    <v-card
      tile
      class="elevation-0 news-showcase-card"
      @click="showOverlay"
    >
      <div>
        <v-img
          :aspect-ratio="16/9"
          max-height="40vh"
          :contain="newsArticle.properties.image.fit"
          :src="newsArticle.properties.image.url"
        />
      </div>
      <div class="card-info">
        <v-col class="elevation-0 pa-0">
          <div
            :style="{background, color}"
            class="article-top-title"
          >
            {{ newsArticle.content.title }}
          </div>
          <div
            :style="{background, subtitleColor}"
            class="text-subtitle-1"
          >
            {{ newsArticle.content.subtitle }}
          </div>
        </v-col>
      </div>
    </v-card>
    <v-overlay
      :value="overlay"
      color="white"
      opacity="1"
      z-index="1"
      class="align-start justify-start d-block news-overlay"
      :class="drawerOpened ? 'width-normal' : 'width-wider'"
    >
      <div class="overlay-article-wrapper">
        <v-row
          no-gutters
          class="mt-2"
        >
          <v-col class="pt-4">
            <v-btn
              color="primary"
              @click="hideOverlay"
            >
              <v-icon
                small
                class="mr-2"
              >
                {{ 'mdi-chevron-left' }}
              </v-icon>
              {{ $t('base.back') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card
              class="rounded-0 elevation-0 white black--text"
              tile
            >
              <v-row class="mb-4">
                <v-col>
                  <div class="news-overlay-title text-uppercase font-italic">
                    {{ newsArticle.content.title }}
                  </div>
                  <div class="news-overlay-subtitle">
                    {{ newsArticle.content.subtitle }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="mb-5 pt-0">
                <v-col class="py-0 text-right">
                  <v-card-subtitle
                    class="grey--text pa-0 published-date pr-3"
                    v-html="dates"
                  />
                  <v-divider
                    class="mt-1"
                    light
                  />
                </v-col>
              </v-row>
              <v-row class="news-overlay-img-holder mb-4">
                <v-col width="100%">
                  <v-img
                    :src="newsArticle.properties.image.url"
                    :contain="newsArticle.properties.image.fit"
                  />
                </v-col>
              </v-row>
              <v-divider
                class="mt-6 mb-4"
                light
              />
              <v-row>
                <v-col class="px-5">
                  <p
                    class="text-justify news-body mb-1 text-pre-wrap"
                    v-html="newsArticle.content.body"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import {
  hideDomElementsByClass,
  showDomElementsByClass,
  removeNewsContainerPseudoElementBorder,
  revertNewsContainerPseudoElementBorder
} from '../js/homeViewHelpers'

import { formatSqlDateTime } from '@/global/services/helpers/dates'

export default {
  name: 'NewsArticle',
  props: {
    newsArticle: {
      type: Object,
      default: () => ({
        first_published: '',
        last_updated: '',
        image: {
          url: '',
          fit: false
        },
        content: {
          title: '',
          body: ''
        }
      })
    },
    drawerOpened: {
      type: Boolean,
      default: true
    },
    background: {
      type: String,
      default: 'white'
    },
    color: {
      type: String,
      default: 'red'
    },
    subtitleColor: {
      type: String,
      default: 'black'
    }
  },
  data () {
    return {
      overlay: false
    }
  },

  computed: {
    dates () {
      const published = formatSqlDateTime(this.newsArticle.first_published)
      const updated = formatSqlDateTime(this.newsArticle.last_updated)
      if (published === updated) {
        return this.$t('base/news.published_on')
          .replace(':published', published)
      }
      else {
        return this.$t('base/news.updated_on')
          .replace(':published,', published + '<br>')
          .replace(':updated', updated)
      }
    }
  },

  watch: {
    overlay: function (overlay) {
      if (screen.width > 767) {
        this.$emit('article-opened-closed', overlay)
      }
    }
  },

  methods: {
    showOverlay () {
      this.overlay = !this.overlay
      hideDomElementsByClass('news-showcase-card')
      hideDomElementsByClass('posts-separator')
      removeNewsContainerPseudoElementBorder()
    },
    hideOverlay () {
      this.overlay = false
      showDomElementsByClass('news-showcase-card')
      showDomElementsByClass('posts-separator')
      revertNewsContainerPseudoElementBorder()
    }
  }
}
</script>

<style scoped lang="scss">
  .news-article {
    height: calc(100% - 50px);
  }

  .news-overlay-title {
    font-family: "DrukText-BoldItalic", sans-serif;
    font-weight: 400;
    font-size: 3rem;
    line-height: 4.5rem;
    letter-spacing: 0;
    color: #FE4020;
    text-transform: uppercase;
  }

  .news-overlay-subtitle {
    font-family: "SairaExtraCondensed-Bold",sans-serif;
    font-weight: 400;
    font-size: 2.75rem;
    line-height: 3rem;
    color: #000;
  }

  .published-date {
    font-family: "SairaExtraCondensed-Bold",sans-serif;
    font-weight: 100;
    font-size: 0.98rem;
    line-height: 1.1rem;
    letter-spacing: .056rem;
    color: gray;
  }

  .news-overlay-img-holder :first-child{
    padding: 0;
  }

  .news-showcase-card {
    height: 100%;
    .card-info {
      transform: translateY(-50px);
      background-color: white;
      position: relative;
      width: 84%;
      margin: 0 8%;
      font-weight: 500;
      line-height: 1.2;
      padding: 16px 20px 10px 20px
    }
  }

  .article-top-title {
    font-family: 'DrukWide-SuperItalic', sans-serif;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 1.875rem;
    line-height: 1.875rem;
    color: #FA292A;
    margin-bottom: 6px;
    width: 100%;
  }

  .text-subtitle-1 {
    font-family: 'SairaExtraCondensed-Bold', sans-serif !important;
    font-size: 2.81rem !important;
    font-weight: 100;
    line-height: 3.1rem;
    letter-spacing: -1.35px !important;
    padding-bottom: 0;
    color: #1D1D1D;
  }

  .news-overlay {
    overscroll-behavior: contain;
    transform: translateY(56px);
    padding-bottom: 65px; /* needs for full content to be visible, this is navbar height */
    margin: 0 auto;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox 64 */
  }

  .news-overlay::-webkit-scrollbar { /* WebKit */
    width: 0;
  }

  .news-body {
    line-height: 1.5rem;
  }

  @media (max-width: 767px) {
    .news-overlay-title {
      font-size: 1.4rem;
      line-height: 1.5rem;
    }

    .news-overlay-subtitle {
      font-size: 2.5rem;
      line-height: 2.6rem;
    }

    .news-overlay {
      width: 100%;
      padding: 0 20px 65px 20px;
    }

    .news-showcase-card {
      position: static;
      height: auto;
      .card-info {
        transform: none;
        position: relative;
        padding-top: 0;
        margin: 0;
        width: 100%;
        //text-align: center;
      }
    }
    .article-top-title {
      font-size: 1.25rem;
      line-height: 1.3rem;
      margin-top: 5px;
      letter-spacing: 0.25px;
      width: 100%;
    }
    .text-subtitle-1 {
      font-size: 2rem !important;
      line-height: 2.2rem;
      letter-spacing: -0.9px !important;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1239px){
    .article-top-title {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    .news-overlay {
      width: 100%;
      padding: 0 20px 65px 20px;
    }

    .news-overlay-title {
      font-size: 2.15rem;
      line-height: 2.4rem;
    }
  }

  @media screen and (min-width: 1240px) {

    .overlay-article-wrapper {
      border-left: 2px solid #E2E2E2;
      border-right: 2px solid #E2E2E2;
      margin: 20px;
      padding: 0 20px;
    }

    .width-normal{
      transform: translateY(60px);
      width: calc(100% - 256px); // 256px is drawer width
      padding: 0 20% 65px 20%;
      margin: 0 0 0 256px;
    }

    .width-wider{
      transform: translateY(60px);
      width: 100%;
      padding: 0 21.5% 65px 21.5%;
    }

    .news-overlay::-webkit-scrollbar { /* WebKit */
      width: 0;
    }

    .news-overlay-title {
      font-size: 2.15rem;
      line-height: 2.4rem;
    }
  }
</style>
