<template>
  <div
    class="news-article"
  >
    <!-- Showcase card / open button -->
    <v-card
      class="elevation-0 news-showcase-card news-card"
      tile
      @click="showOverlay"
    >
      <v-img
        :aspect-ratio="16/9"
        max-height="40vh"
        width="100%"
        :contain="newsArticle.properties.image.fit"
        :src="newsArticle.properties.image.url"
      />
      <div class="article-wrapper">
        <div
          class="article-title"
          :style="titleStyle"
        >
          {{ newsArticle.content.title }}
        </div>
        <div
          class="article-subtitle"
        >
          {{ newsArticle.content.subtitle }}
        </div>
      </div>
    </v-card>
    <v-overlay
      :value="overlay"
      color="white"
      opacity="1"
      z-index="1"
      class="align-start justify-start d-block news-overlay"
      :class="drawerOpened ? 'width-normal' : 'width-wider'"
    >
      <div class="overlay-article-wrapper">
        <v-row
          no-gutters
          class="mt-2"
        >
          <v-col class="pt-4">
            <v-btn
              color="primary"
              @click="hideOverlay"
            >
              <v-icon
                small
                class="mr-2"
              >
                {{ 'mdi-chevron-left' }}
              </v-icon>
              {{ $t('base.back') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card
              class="rounded-0 elevation-0 white black--text"
              tile
            >
              <v-row class="mb-4">
                <v-col>
                  <div class="news-overlay-title text-uppercase font-italic">
                    {{ newsArticle.content.title }}
                  </div>
                  <div class="news-overlay-subtitle">
                    {{ newsArticle.content.subtitle }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="mb-5 pt-0">
                <v-col class="py-0 text-right">
                  <v-card-subtitle
                    class="grey--text pa-0 published-date pr-3"
                    v-html="dates"
                  />
                  <v-divider
                    class="mt-1"
                    light
                  />
                </v-col>
              </v-row>
              <v-row class="news-overlay-img-holder mb-4">
                <v-col height="400px">
                  <v-img
                    :src="newsArticle.properties.image.url"
                    :contain="newsArticle.properties.image.fit"
                  />
                </v-col>
              </v-row>
              <v-divider
                class="my-6"
                light
              />
              <v-row>
                <v-col class="px-5">
                  <p
                    class="text-justify news-body mb-1 text-pre-wrap"
                    v-html="newsArticle.content.body"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { formatSqlDateTime } from '@/global/services/helpers/dates'
import {
  hideDomElementsByClass,
  removeNewsContainerPseudoElementBorder,
  revertNewsContainerPseudoElementBorder,
  showDomElementsByClass
} from '../js/homeViewHelpers'

export default {
  name: 'NewsArticle',

  props: {
    newsArticle: {
      type: Object,
      default: () => ({
        first_published: '',
        last_updated: '',
        properties: {
          image: {
            url: '',
            fit: false
          }
        },
        content: {
          title: '',
          subtitle: '',
          body: ''
        }
      })
    },
    drawerOpened: {
      type: Boolean,
      default: true
    },
    background: {
      type: String,
      default: 'white'
    },
    color: {
      type: String,
      default: 'black'
    }
  },

  data () {
    return {
      overlay: false
    }
  },

  computed: {
    titleStyle () {
      let background
      let color
      if (screen.width <= 767) {
        background = 'none'
        color = this.background
      }
      else {
        background = this.background
        color = this.color
      }

      return { background, color }
    },

    dates () {
      const published = formatSqlDateTime(this.newsArticle.first_published)
      const updated = formatSqlDateTime(this.newsArticle.last_updated)
      if (published === updated) {
        return this.$t('base/news.published_on')
          .replace(':published', published)
      }
      else {
        return this.$vuetify.breakpoint.width <= 767
          ? this.$t('base/news.updated_on')
            .replace(':published,', published + '<br>')
            .replace(':updated', updated)
          : this.$t('base/news.updated_on')
            .replace(':published', published)
            .replace(':updated', updated)
      }
    }
  },

  watch: {
    overlay (overlay) {
      if (screen.width > 767) {
        this.$emit('article-opened-closed', overlay)
      }
    }
  },

  methods: {
    showOverlay () {
      this.overlay = !this.overlay
      hideDomElementsByClass('news-showcase-card')
      hideDomElementsByClass('posts-separator')
      removeNewsContainerPseudoElementBorder()
    },

    hideOverlay () {
      this.overlay = false
      showDomElementsByClass('news-showcase-card')
      showDomElementsByClass('posts-separator')
      revertNewsContainerPseudoElementBorder()
    }
  }
}
</script>

<style scoped lang="scss">
  p {
    line-height: 1.5;
  }

  .news-overlay-title {
    font-family: "DrukText-BoldItalic", sans-serif;
    font-weight: 400;
    font-size: 3rem;
    line-height: 4.5rem;
    letter-spacing: 0;
    color: #FE4020;
    text-transform: uppercase;
  }

  .news-overlay-subtitle {
    font-family: "SairaExtraCondensed-Bold",sans-serif;
    font-weight: 400;
    font-size: 2.75rem;
    line-height: 3rem;
    color: #000;
  }

  .published-date {
    font-family: "SairaExtraCondensed-Bold",sans-serif;
    font-weight: 100;
    font-size: 0.98rem;
    line-height: 1.1rem;
    letter-spacing: .056rem;
    color: gray;
  }

  .news-overlay-img-holder :first-child{
    padding: 0;
  }

  .article-title {
    margin-left: 10px;
    margin-bottom: 10px;
    color: white;
    padding: 0.6px 4px 0.9px 6px;
    -webkit-transform: skewX(
      -10deg
    ) skewY(
      0deg
    );
    transform: skewX(-10deg) skewY(0deg);
    margin-top: 4px;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-table;
    line-height: normal;
  }

  .article-subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 27.5px;
    letter-spacing: -0.75px;
    color: #1D1D1D;
    padding-right: 1.7rem;
  }

  .news-overlay {
    overscroll-behavior: contain;
    transform: translateY(56px);
    padding: 0 20px 65px 20px;
    margin: 0 auto;
    overflow: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox 64 */
  }

  .news-overlay::-webkit-scrollbar { /* WebKit */
    width: 0;
  }

  .news-body {
    line-height: 1.5rem;
  }

  @media (max-width: 767px) {
    .news-overlay-title {
      font-size: 1.4rem;
      line-height: 1.5rem;
    }

    .news-overlay-subtitle {
      font-size: 2.5rem;
      line-height: 2.6rem;
    }

    .news-overlay {
      width: 100%;
      margin: 0 auto;
      padding: 0 20px 65px 20px;
      overflow: auto;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox 64 */
    }

    .news-card {
      display: flex;
      flex-direction: row;
      margin: 1rem 0 2rem 0;
      .v-image {
        max-width: 11.25rem;
        height: auto;
        vertical-align: middle;
      }
      .article-wrapper {
        margin: 0;
        padding-left: 1rem;
        .article-title {
          font-family: 'DrukText-BoldItalic', sans-serif;
          font-size: 1rem;
          line-height: 1rem;
          letter-spacing: 0.4px;
          color: #FA292A;
          background-color: unset;
          margin: 0;
          padding: 0;
          transform: none;
          font-weight: 100;
          text-transform: uppercase;
        }
        .article-subtitle {
          //font-size: 1rem;
          font-size: 1.25rem;
          line-height: 1.25rem;
          letter-spacing: 0;
          margin-top: 0;
          padding-right: 0;
          color: #1D1D1D;
          font-weight: 100;
          font-family: 'SairaExtraCondensed-Bold', sans-serif;
        }
      }
    }

    .container {
      padding: 0;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1239px) {

    .news-overlay {
      width: 100%;
      padding: 0 20px 65px 20px;
    }

    .news-overlay-title {
      font-size: 2.15rem;
      line-height: 2.4rem;
    }
  }

  @media screen and (min-width: 1240px) {

    .overlay-article-wrapper {
      border-left: 2px solid #E2E2E2;
      border-right: 2px solid #E2E2E2;
      margin: 20px;
      padding: 0 20px;
    }

    .width-normal {
      transform: translateY(60px);
      width: calc(100% - 256px); // 256px is drawer width
      padding: 0 20% 65px 20%;
      margin: 0 0 0 256px;
    }

    .width-wider {
      transform: translateY(60px);
      width: 100%;
      padding: 0 21.5% 65px 21.5%;
    }

    .news-overlay::-webkit-scrollbar { /* WebKit */
      width: 0;
    }

    .news-overlay-title {
      font-size: 2.15rem;
      line-height: 2.4rem;
    }
  }
</style>
